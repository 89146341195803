import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ActualiteDataService } from 'src/app/core/services/backend/actualite-data.service';
import { NavbarDataService } from 'src/app/core/services/backend/navbar-data.service';

@Component({
  selector: 'app-article-item',
  templateUrl: './article-item.component.html',
  styleUrls: ['./article-item.component.scss']
})
export class ArticleItemComponent implements OnInit {

  article: any;

  constructor(private navbarDataService: NavbarDataService,
    private acutaliteDataService: ActualiteDataService,
    private route: ActivatedRoute,) {
    this.route.paramMap.subscribe(paramMap => {
      if (route.snapshot.data['type'] === 'article') {
        this.readArticle(paramMap.get('articleTitle'));
      } else {
        this.readActualite(paramMap.get('articleTitle'));
      }
    });
  }

  ngOnInit(): void {

  }

  readArticle(articleTitle: string) {
    this.navbarDataService.findArticleByTitle(articleTitle).subscribe(
      (article: any) => {
        this.article = article;
      },
      (error) => {
        // TODO: Handle error
      }
    )
  }

  readActualite(actuTitle: string) {
    this.acutaliteDataService.findByTitle(actuTitle).subscribe(
      (actu: any) => {
        this.article = actu;
      },
      (error) => {
        // TODO: Handle error
      }
    )
  }

}
