import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

export interface CustomLaguageConfig {
  language: string,
  direction: 'rtl' | 'ltr'
}

const FR: CustomLaguageConfig = { language: 'fr', direction: 'ltr' }
const ENG: CustomLaguageConfig = { language: 'en', direction: 'ltr' }
const AR: CustomLaguageConfig = { language: 'ar', direction: 'rtl' }
const AM: CustomLaguageConfig = { language: 'am', direction: 'ltr' }

@Injectable({
  providedIn: 'root'
})
export class TraductionService {

  private _langConfig: BehaviorSubject<CustomLaguageConfig> = new BehaviorSubject<CustomLaguageConfig>(FR);
  langConfig$ = this._langConfig.asObservable();

  _langSub = new BehaviorSubject([]);
  langs$ = this._langSub.asObservable();

  constructor(private translate: TranslateService,
    private http: HttpClient,) {

    this.translate.setDefaultLang('fr');
  }

  emitActiveLanguages(langs: any) {
    this._langSub.next(langs.filter(l => l.active));
  }

  setLanguage(lang: string) {
    this.translate.use(lang);

    switch (lang) {
      case 'fr':
        this._langConfig.next(FR);
        break;
      case 'en':
        this._langConfig.next(ENG);
        break;
      case 'ar':
        this._langConfig.next(AR);
        break;
      case 'ar':
        this._langConfig.next(AM);
        break;
    }
  }

  findAllLanguages() {
    return this.http.get(`${environment.API_URL}/api/langs/`);
  }
}
