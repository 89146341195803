import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { faCheck, faInfoCircle, faTimes } from '@fortawesome/free-solid-svg-icons';
import { Subject } from 'rxjs';
import { NotificationComponent } from 'src/app/shared/components/notification/notification.component';

@Injectable({
  providedIn: 'root'
})
export class SnackbarService {

  faCheck = faCheck;
  faInfoCircle = faInfoCircle;
  faTimes = faTimes;

  _errorSub = new Subject();
  error$ = this._errorSub.asObservable();

  constructor(private snackBarService: MatSnackBar) { }

  showResult(msg: string, action: string) {
    this.snackBarService.open(msg, action, {
      duration: 2000
    });
  }

  showInfo(msg: string) {
    this.snackBarService.openFromComponent(NotificationComponent, {
      panelClass: 'snackbar--info',
      horizontalPosition: 'center',
      verticalPosition: 'bottom',
      duration: 5000,
      data: { msg: msg, iconClass: "icon icon--info", iconRef: faInfoCircle }
    });
  }

  showError(msg: string) {
    this.snackBarService.openFromComponent(NotificationComponent, {
      panelClass: 'snackbar--error',
      horizontalPosition: 'center',
      verticalPosition: 'bottom',
      duration: -1,
      data: { msg: msg, iconClass: "icon icon--error", iconRef: faTimes }
    });
  }

  showSuccess(msg: string) {
    this.snackBarService.openFromComponent(NotificationComponent, {
      panelClass: 'snackbar--success',
      horizontalPosition: 'center',
      verticalPosition: 'bottom',
      duration: 2000,
      data: { msg: msg, iconClass: "icon icon--success", iconRef: faCheck }
    });
  }

  setErrorMsg(error: any) {
    if (error.status === 0 || error.status === 500)
      return "Erreur interne du serveur";
    /*if (error.status === 400)
      return ""*/
  }

  emitError(msg: string, visible: boolean) {
    this._errorSub.next({
      msg: msg,
      visible: visible
    });
  }

  dismiss() {
    this.snackBarService.dismiss();
  }
}
