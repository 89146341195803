import { Injectable } from '@angular/core';
import { View, Map, Feature } from 'ol';
import { fromLonLat } from 'ol/proj';
import VectorSource from 'ol/source/Vector';
import GeoJSON from 'ol/format/GeoJSON';
import { Coordinate } from 'ol/coordinate';
import Style from 'ol/style/Style';
import Fill from 'ol/style/Fill';
import Stroke from 'ol/style/Stroke';
import { Icon, Text } from 'ol/style';
import Point from 'ol/geom/Point';
import { environment } from 'src/environments/environment';
import { BehaviorSubject, Subject } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import {
  faBolt,
  faCloudRain,
  faSnowflake,
  faTemperatureHigh,
  faTemperatureLow,
  faWind,
} from '@fortawesome/free-solid-svg-icons';
import IconAnchorUnits from 'ol/style/IconAnchorUnits';
import { getCenter } from 'ol/extent';

declare global {
  interface Window {
    opera: any;
  }
}

@Injectable({
  providedIn: 'root',
})
export class MapHandlerService {
  // map loader
  _mapLoadingSub: BehaviorSubject<boolean> = new BehaviorSubject(true);
  mapLoading$ = this._mapLoadingSub.asObservable();

  // vigilance table loader
  _vigiTableLoadingSub: BehaviorSubject<boolean> = new BehaviorSubject(true);
  vigiTableLoading$ = this._vigiTableLoadingSub.asObservable();

  _minimapSub: Subject<string> = new Subject();
  minimapHandler$ = this._minimapSub.asObservable();

  // Vector Source
  readonly VGJsonPath: string = '/assets/wilaya58.json';
  readonly VMJsonPath: string = '/assets/marine58.json';

  readonly VGPREV_ZOOM = 5.4;
  readonly VGPREV_CENTER = fromLonLat([1.44197, 28.7525]);

  readonly VM_ZOOM = 6.2;
  readonly VM_CENTER = fromLonLat([3.44197, 34.7525]);

  // Map Styles
  yellowStyle = new Style({
    fill: new Fill({
      color: '#FFFF3B',
    }),
    stroke: new Stroke({
      color: '#34495E',
      width: 0.5,
    }),
  });

  greenStyle = new Style({
    fill: new Fill({
      color: '#409934',
    }),
    stroke: new Stroke({
      color: '#34495E',
      width: 0.5,
    }),
  });

  orangeStyle = new Style({
    fill: new Fill({
      color: '#FF6A33',
    }),
    stroke: new Stroke({
      color: '#34495E',
      width: 0.5,
    }),
  });

  redStyle = new Style({
    fill: new Fill({
      color: '#FF3333',
    }),
    stroke: new Stroke({
      color: '#34495E',
      width: 0.5,
    }),
  });

  previsionGreenStyle = new Style({
    fill: new Fill({
      color: '#89C863', //a9e386
    }),
    stroke: new Stroke({
      color: '#34495E',
      width: 0.5,
    }),
  });

  selectedWilayaStyle = new Style({
    stroke: new Stroke({
      color: '#fff',
      width: 2,
    }),
  });

  greyStyle = new Style({
    fill: new Fill({
      color: '#FCF3CF',
    }),
    stroke: new Stroke({
      color: '#909497',
      width: 0.5,
    }),
  });

  // --- NEW CODE ---
  VGPrevFeatures;
  VMFeatures;
  miniMapFeatures;

  constructor(private http: HttpClient) {}

  // --- NEW CODE ---
  async readVGPrevFeatures() {
    if (!this.VGPrevFeatures) {
      const geoJSON = await this.loadVGPrevFeatures().toPromise();
      const format = new GeoJSON({
        featureProjection: 'EPSG:3857',
      });
      this.VGPrevFeatures = format.readFeatures(geoJSON);
    }

    return [...this.VGPrevFeatures];
  }

  async readMiniMapFeatures() {
    if (!this.miniMapFeatures) {
      const geoJSON = await this.loadVGPrevFeatures().toPromise();
      const format = new GeoJSON({
        featureProjection: 'EPSG:3857',
      });
      this.miniMapFeatures = format.readFeatures(geoJSON);
    }

    return [...this.miniMapFeatures];
  }

  async readVMFeatures() {
    if (!this.VMFeatures) {
      const geoJSON = await this.loadVMFeatures().toPromise();
      const format = new GeoJSON({
        featureProjection: 'EPSG:3857',
      });
      this.VMFeatures = format.readFeatures(geoJSON);
    }

    return [...this.VMFeatures];
  }

  mobileAndTabletCheck() {
    let check = false;
    (function (a) {
      if (
        /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino|android|ipad|playbook|silk/i.test(
          a
        ) ||
        /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
          a.substr(0, 4)
        )
      )
        check = true;
    })(navigator.userAgent || navigator.vendor || window.opera);
    return check;
  }

  navigateMinimap(mapMode: string) {
    this._minimapSub.next(mapMode);
  }

  // --- LEGACY CODE ---

  getLogoIcon(point: Point, scale = 1, rotation = 0, opacity = 0.8) {
    return new Style({
      geometry: point,
      image: new Icon({
        //anchor: [0.5, 20],
        //anchorXUnits: IconAnchorUnits.FRACTION,
        //anchorYUnits: IconAnchorUnits.PIXELS,
        src: 'assets/img/tram_protect.png',
        rotation: rotation,
        scale: scale,
        opacity: opacity,
      }),
      /*text: new Text({
        font: '24px sans-serif',
        fill: new Fill({ color: 'white' }),
        stroke: new Stroke({ color: 'rgba(0,0,0,0.1)', width: 4 }),
        text: "MÉTÉO ALGÉRIE",
        rotation: 32,
      })*/
    });
  }

  getPortIcon(name: string, point: Point) {
    return new Style({
      geometry: point,
      image: new Icon({
        anchor: [0.5, 30],
        anchorXUnits: IconAnchorUnits.FRACTION,
        anchorYUnits: IconAnchorUnits.PIXELS,
        src: 'assets/icons/anchor.png',
        //rotation: 32,
        scale: 0.8,
        opacity: 1,
      }),
      text: new Text({
        offsetY: -40,
        // font: '12px sans-serif',
        font: '12px sansationBold',
        fill: new Fill({ color: 'black' }),
        stroke: new Stroke({ color: 'rgba(1,77,120,0.1)', width: 2 }),
        text: name,
      }),
    });
  }

  getStationIcon() {
    return new Style({
      image: new Icon({
        //anchor: [0.5, 20],
        anchorXUnits: IconAnchorUnits.FRACTION,
        anchorYUnits: IconAnchorUnits.FRACTION,
        src: 'assets/icons/station.png',
        scale: 1,
      }),
    });
  }

  addTextToMap(vectorSource: VectorSource, mapMode: string, point: Point) {
    let textFeature = vectorSource.getFeatures().find((f) => {
      return f.get('isMapText');
    });
    if (!textFeature) {
      const iconFeature = new Feature({
        geometry: point,
        isMapText: true,
      });

      iconFeature.setStyle(this.getLogoIcon(point));

      vectorSource.addFeature(iconFeature);
    } else {
      textFeature.setStyle(this.getLogoIcon(point));
    }
  }

  generateVGPrevMapText(): Feature[] {
    let features: Feature[] = [];
    /* const e=new r.a({geometry:new f.a([187744.0681669082,4089474.110901538]),isMapText:!0}),
    n=new r.a({geometry:new f.a([310669.86974557524,3573841.7456322126]),isMapText:!0}),
    i=new r.a({geometry:new f.a([499748.28169437347,2673139.39546902]),isMapText:!0}),
    j=new r.a({geometry:new f.a([754271.739814,4008614.863466]),isMapText:!0}),
    z=new r.a({geometry:new f.a([-150000.0681669082,3589474.110901538]),isMapText:!0}),
    w=new r.a({geometry:new f.a([800000.0681669082,3589474.110901538]),isMapText:!0}),
    gh=new r.a({geometry:new f.a([-150000.0681669082,3089474.110901538]),isMapText:!0});*/

    const a = new Feature({
      geometry: new Point([187744.0681669082, 4089474.110901538]),
      isMapText: true,
    });
    const b = new Feature({
      geometry: new Point([310669.86974557524, 3573841.7456322126]),
      isMapText: true,
    });

    const c = new Feature({
      geometry: new Point([499748.28169437347, 2673139.39546902]),
      isMapText: true,
    });
    const d = new Feature({
      geometry: new Point([754271.739814, 4008614.863466]),
      isMapText: true,
    });

    const e = new Feature({
      geometry: new Point([-150000.0681669082, 3589474.110901538]),
      isMapText: true,
    });
    const f = new Feature({
      geometry: new Point([800000.0681669082, 3589474.110901538]),
      isMapText: true,
    });
    const g = new Feature({
      geometry: new Point([-150000.0681669082, 3089474.110901538]),
      isMapText: true,
    });
    features.push(a, b, c, d, e, f, g);
    features.forEach((f) =>
      f.setStyle(
        new Style({
          //geometry: point,
          image: new Icon({
            src: 'assets/img/tram_protect.png',
            rotation: 0,
            scale: 1,
            opacity: 0.8,
          }),
        })
      )
    );
    return features;
  }

  loadVGPrevFeatures() {
    return this.http.get(this.VGJsonPath);
  }

  loadVMFeatures() {
    return this.http.get(this.VMJsonPath);
  }

  setVGStyle(vectorSource: VectorSource, mapData: any) {
    vectorSource.getFeatures().forEach((feature) => {
      const wilaya = mapData.find((w) => w.numero === feature.get('CODE'));
      if (wilaya) {
        let featureStyle = this.getVigilanceStyleByGravite(wilaya);

        feature.setStyle(featureStyle);
        /*if (wilaya.numero === 17 || wilaya.numero === 50 || wilaya.numero === 11 || wilaya.numero === 1) {
          const center = getCenter(feature.getGeometry().getExtent());
          feature.setStyle([featureStyle, this.getLogoIcon(new Point(center))]);
        }*/
        //feature.set('VGStyle', featureStyle);
        feature.set('VGProps', {
          alerte: wilaya['Alerte'],
          phenomene: wilaya['PHENO'],
          gravite: wilaya['gravite'],
          icon: this.setIconByPhenomene(wilaya['PHENO']),
          prev_alerte: wilaya['prev_alerte'] ? wilaya['prev_alerte'] : '',
        });
      } else {
        feature.setStyle(this.greenStyle);
        feature.set('VGStyle', this.greenStyle);
      }
    });
  }

  setIconByPhenomene(ph: string) {
    switch (ph) {
      case 'CANICULE':
        return {
          icon: null,
          faIcon: faTemperatureHigh,
          img: 'assets/icons/canicule.png',
        };
      case 'GRAND-FROID':
        return {
          icon: null,
          faIcon: faTemperatureLow,
          img: 'assets/icons/grand-froid.png',
        };
      case 'NEIGE&VERGLAS':
        return {
          icon: null,
          faIcon: faSnowflake,
          img: 'assets/icons/neige&verglas.png',
        };
      case 'ORAGE':
        return { icon: null, faIcon: faBolt, img: 'assets/icons/orage.png' };
      case 'PLUIE':
        return {
          icon: null,
          faIcon: faCloudRain,
          img: 'assets/icons/pluie.png',
        };
      case 'VENT-VIOLENT':
        return {
          icon: null,
          faIcon: faWind,
          img: 'assets/icons/vent-violent.png',
        };
      case 'VENT-DE-SABLE':
        return {
          icon: 'icon-sandstorm',
          faIcon: null,
          img: 'assets/icons/vent-de-sable.png',
        };
      case 'VAGUE-DANGEREUSE':
        return {
          icon: 'icon-wave',
          faIcon: null,
          img: 'assets/icons/vague-dangereuse.png',
        };
      default:
        return { icon: null, faIcon: null, img: null };
    }
  }

  setVMStyle(vectorSource: VectorSource, mapData: any) {
    vectorSource.getFeatures().forEach((feature) => {
      const wilaya = mapData.find((w) => w.nom === feature.get('Zone'));
      if (wilaya) {
        const featureStyle = this.getVigilanceStyleByGravite(wilaya);
        feature.setStyle(featureStyle);
        feature.set('VMStyle', featureStyle);
        feature.set('VMProps', {
          alerte: wilaya['Alerte'],
          phenomene: wilaya['PHENO'],
          gravite: wilaya['gravite'],
          icon: this.setIconByPhenomene(wilaya['PHENO']),
        });

        // Main ports
        if (
          wilaya.nom === 'ALGER-DELLYS' ||
          wilaya.nom === 'SKIKDA-ANNABA' ||
          wilaya.nom === 'BENI-SAF-ORAN'
        ) {
          const center = getCenter(feature.getGeometry().getExtent());
          const iconFeature = new Feature({
            geometry: new Point([center[0], center[1]]),
          });
          feature.setStyle([
            featureStyle,
            this.getPortIcon(wilaya.nom, new Point(center)),
          ]);
        }
      } else {
        feature.setStyle(this.greyStyle);
        feature.set('VMStyle', this.greyStyle);
      }
    });
    this.addTextToMap(
      vectorSource,
      'vigilanceMarine',
      new Point([352282.72143964516, 4387949.320537492])
    );
  }

  setPrevStyle(vectorSource: VectorSource, mapData: any, withText = true) {
    vectorSource.getFeatures().forEach((feature) => {
      const wilaya = mapData.find((w) => w.num === feature.get('CODE')); // TODO: Prendre en compte la recherche
      if (wilaya) {
        const iconStyle = new Style({
          geometry: new Point(fromLonLat([wilaya.longitude, wilaya.latitude])),
          image: new Icon({
            //anchor: [0.5, 20],
            //anchorXUnits: IconAnchorUnits.FRACTION,
            //anchorYUnits: IconAnchorUnits.PIXELS,
            src: environment.API_VIGILANCE + wilaya['État du ciel'],
            scale: 0.4,
          }),
          text: withText
            ? new Text({
                //font: '14px Roboto',
                font: '14px sansationBold',
                scale: 1,
                fill: new Fill({ color: '#17202A' }),
                offsetY: -16,
                offsetX: 15,
                stroke: new Stroke({ color: '#014D78', width: 1 }),
                text: '' + wilaya['Temp'],
              })
            : null,
        });
        const fillStyle = this.previsionGreenStyle;
        feature.setStyle([iconStyle, fillStyle]);
        feature.set('prevStyle', [iconStyle, fillStyle]);
        feature.set('prevData', { ...wilaya });
      } else {
        feature.setStyle(this.previsionGreenStyle);
        feature.set('prevStyle', this.previsionGreenStyle);
      }
    });
  }

  placeMap(map: Map, center: Coordinate, zoom: number) {
    this.getMapView(map).setCenter(center);
    this.getMapView(map).setZoom(zoom);
  }

  getMapView(map: Map): View {
    return map.getView();
  }

  generateVigilanceHighlightStyle(chosenFill: Fill, chosenStroke: Stroke) {
    return new Style({
      fill: chosenFill,
      stroke: chosenStroke,
    });
  }

  generatePrevHightlightStyle(prevStyle: any) {
    const iconStyle = prevStyle[0];
    const fillStyle = prevStyle[1];
    /*const styles = [];
    styles.push(iconStyle)
    styles.push(...fillStyle,stroke: new Stroke({
      color: '#34495E',
      width: 1,
    }))*/
    /*const iconStyle = new Style({
      geometry: new Point(fromLonLat([wilaya.longitude, wilaya.latitude])),
      image: new Icon({
        //anchor: [0.5, 20],
        //anchorXUnits: IconAnchorUnits.FRACTION,
        //anchorYUnits: IconAnchorUnits.PIXELS,
        src: 'assets/icons/station.png',
        scale: 1
      })
    })
    const fillStyle = new Style({
      fill: new Fill({
        color: '#89C863',
      }),
    });*/
    return [iconStyle, fillStyle];
  }

  getVigilanceStyleByGravite(wilaya: any) {
    if (wilaya.gravite === 'VERT') return this.greenStyle;
    if (wilaya.gravite === 'JAUNE') return this.yellowStyle;
    if (wilaya.gravite === 'ORANGE') return this.orangeStyle;
    if (wilaya.gravite === 'ROUGE') return this.redStyle;
  }

  zoomOut(map: Map, zoom: number, selectedEasing: any) {
    map.getView().animate({
      easing: selectedEasing,
      center: fromLonLat([1.44197, 28.7525]),
      duration: 500,
      zoom: zoom,
    });
  }

  zoomInFeature(map: Map, f: Feature, selectedEasing: any) {
    const extent = f.getGeometry().getExtent();
    map.getView().fit(extent, { duration: 500, easing: selectedEasing });
  }

  initMapLoading() {
    this._mapLoadingSub.next(true);
  }

  stopMapLoading() {
    this._mapLoadingSub.next(false);
  }

  initVigiTableLoading() {
    this._vigiTableLoadingSub.next(true);
  }

  stopVigiTableLoading() {
    this._vigiTableLoadingSub.next(false);
  }
}
