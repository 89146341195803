import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class NavbarDataService {

  constructor(private http: HttpClient) { }

  findAll() {
    return this.http.get(`${environment.API_URL}/api/navbarmenus/`);
  }

  findByTitre() {
    return this.http.get(`${environment.API_URL}/api/navbarmenus/`);
  }

  findArticlesByPageId(id: string) {
    return this.http.get(`${environment.API_URL}/api/navbarpages/articles/${id}/`);
  }

  findArticlesByPageTitle(title: string) {
    return this.http.get(`${environment.API_URL}/api/navbarpagesurl/${title}/`);
  }

  findArticleByTitle(title: string) {
    return this.http.get(`${environment.API_URL}/api/articlesurl/${title}/`);
  }

  findAllTopLinks() {
    return this.http.get(`${environment.API_URL}/api/toplinks/`);
  }

}
