<div class="article-container">
    <div *ngIf="article else loading;">
        <div class="article-wrapper">
            <app-article-view [article]="article"></app-article-view>
        </div>
    </div>

    <ng-template #loading>
        <div class="spinner-container">
            <mat-progress-spinner color="primary" mode="indeterminate">
            </mat-progress-spinner>
        </div>
    </ng-template>
</div>