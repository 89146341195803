<ng-container
  *ngIf="{
    langConfig: langConfig$ | async,
    maintenance: maintenance
  } as obs"
>
  <ng-container *ngIf="obs.langConfig && obs.maintenance">
    <ng-container *ngIf="obs.maintenance.siteweb; else sitewebMaintenanceTpl">
      <!--{{ runChangeDetection }}-->
      <!-- TOP HEADER -->
      <div class="top-header" [style.direction]="obs.langConfig.direction">
        <ng-container>
          <ul *ngIf="langs$ | async as langs" class="top-header__left">
            <li
              *ngFor="let l of langs"
              (click)="setLanguage(l.acr)"
              [ngClass]="{
                'top-link-active': obs.langConfig.language === l.acr
              }"
            >
              {{ l.acr | uppercase }}
            </li>
          </ul>
        </ng-container>

        <ul class="top-header__right">
          <li (click)="newTab('assets/files/a_propos_onm.pdf', true)">
            {{ "app.header.a_propos" | translate }}
          </li>
          <li routerLink="/actualites">{{ "home.actualites" | translate }}</li>
          <li>{{ "app.header.recrutement" | translate }}</li>
          <li routerLink="/contact">{{ "app.header.contact" | translate }}</li>
        </ul>
      </div>

      <!--- SUB-TOP HEADER -->
      <div class="sub-top-header" [style.direction]="obs.langConfig.direction">
        <ul class="sub-top-header__left">
          <li
            *ngFor="let l of topLinks"
            (click)="newTab(l.lien)"
            class="text-primary-blue"
          >
            <i [class]="l.icon" class="mr-xs"></i>
            {{ l["nom_" + obs.langConfig.language] }}
          </li>
        </ul>

        <ul
          class="sub-top-header__right social"
          [style.direction]="obs.langConfig.direction"
        >
          <li
            (click)="
              newTab('https://www.linkedin.com/company/meteoalgerieofficiel')
            "
            class="linkd"
          >
            <!-- <fa-icon
          [icon]="faLinkedinIn"
          class="sub-top-header__icon--right"
        ></fa-icon> -->
          </li>
          <li
            (click)="newTab('https://www.instagram.com/meteoalgerieofficiel/')"
            class="insta"
          >
            <!-- <fa-icon
          [icon]="faInstagram"
          class="sub-top-header__icon--right"
        ></fa-icon> -->
          </li>

          <li
            (click)="newTab('https://www.facebook.com/MeteoAlgerieOfficiel')"
            class="fcb"
          >
            <!-- <fa-icon
          [icon]="faFacebookF"
          class="sub-top-header__icon--right"
        ></fa-icon> -->
          </li>
          <li (click)="newTab('https://twitter.com/MeteoAlgerie_')" class="twt">
            <!-- <fa-icon
          [icon]="faTwitter"
          class="sub-top-header__icon--right"
        ></fa-icon> -->
          </li>
          <li
            (click)="
              newTab('https://www.youtube.com/channel/UC-fiRD9_ncqzsSStt6S3_1w')
            "
            class="ytb"
          >
            <!-- <fa-icon
          [icon]="faYoutube"
          class="sub-top-header__icon--right"
        ></fa-icon> -->
          </li>
          <li
            class="pls"
            (click)="
              newTab('https://play.google.com/store/apps/developer?id=ONM')
            "
          >
            <!-- <fa-icon
          [icon]="faGooglePlay"
          class="sub-top-header__icon--right"
        ></fa-icon> -->
          </li>
        </ul>
      </div>

      <!-- MAIN HEADER -->
      <header class="header">
        <!-- <div style="position: absolute">
          <img
            src="../assets/img/ARC-01.png"
            style="left: 0px; position: relative"
            alt=""
          />
        </div> -->
        <div class="header-educ-space">
          <img
            src="../assets/img/vo_intitul_c1_1.png"
            alt="Logo"
            class="header__image"
            routerLink="/home"
            width="39%"
          />
          <div class="super-educbanner-div">
            <div
              id="educbanner"
              class="p-sm shadow-1 flex-center flex-column rounded-xs"
              (click)="
                newTab(
                  API_URL + '/resources/uploadedpics/espace_educatif.pdf',
                  true
                )
              "
            >
              <span class="text-primary text-bold-500">
                {{ "app.header.espace_educatif" | translate }}
              </span>
              <div>
                <i
                  class="fas fa-graduation-cap"
                  style="font-size: 3.75rem; color: var(--primary-blue)"
                ></i>
              </div>
            </div>

            <div
              id="educbanner"
              class="p-sm shadow-1 flex-center flex-column rounded-xs"
              (click)="newTab('https://www.gloriousalgeria.dz/Ar', true)"
            >
              <div style="display: flex; justify-content: center">
                <picture style="display: flex; justify-content: center">
                  <source
                    media="(max-width:67.5em)"
                    srcset="assets/img/05-juillet1.jpg"
                  />
                  <img
                    src="assets/img/logo-officiel-60.jpg"
                    alt=""
                    width="80%"
                  />
                </picture>
              </div>
            </div>
          </div>
          <!--<img src="assets/img/ramadan.png" alt="Évènement" height="150px" width="100%">-->
        </div>

        <div class="text-center header__text" routerLink="/home">
          <p>الـديــــوان الوطنـــي للأرصــــاد الجويـــــة</p>

          <p>ⴰⵎⴰⵔⵉⵙ ⴰⵖⴻⵍⵏⴰⵡ ⵏ ⵜⵙⴻⵏⵣⵡⵉⵜ</p>
        </div>
        <div class="map-rsss">
          <div
            *ngIf="obs.maintenance.viggen; else maintenanceViggen"
            class="header__minimap"
            (click)="navigateToVG()"
            style="width: 185px; height: 170px"
          >
            <div id="map2" class="map"></div>
            <!--<div class="header__minimap__alert heading-2">Vigilance</div>-->
            <div *ngIf="!mapData" class="spinner-container">
              <mat-progress-spinner color="primary" mode="indeterminate">
              </mat-progress-spinner>
            </div>
          </div>
          <div class="rss-btns">
            <img
              src="assets/icons/icon_atom.png"
              routerLink="/rss"
              alt="RSS"
              style="width: 3rem; height: 3rem; margin: 1rem; cursor: pointer"
            />
            <img
              (click)="
                newTab('https://ametvigilance.meteo.dz/rss/rss_meteo_dz.xml')
              "
              src="assets/icons/icon_rss.png"
              alt="RSS"
              style="width: 3rem; height: 3rem; cursor: pointer"
            />
          </div>
        </div>

        <!-- </div> -->
      </header>

      <!-- HORIZONTAL NAVBAR -->
      <nav class="navbar" [style.direction]="obs.langConfig.direction">
        <ul class="navbar__list">
          <li
            class="navbar__link navbar__link_home"
            routerLink="/home"
            routerLinkActive="active-link"
          >
            <fa-icon class="navbar__icon" [icon]="faHome"></fa-icon>
          </li>
          <li
            *ngFor="let m of navbarMenus"
            class="navbar__link"
            [matMenuTriggerFor]="onmMenu"
            style="font-family: SansationBold"
          >
            {{ m["titre_" + obs.langConfig.language] }}
            <i class="fas fa-angle-down px-sm"></i>
            <mat-menu #onmMenu="matMenu" class="custom-menu">
              <!--[routerLink]="'articles/'+p['titre_'+obs.langConfig.language]"-->
              <button
                style="font-family: SansationBold"
                *ngFor="let p of m.pages"
                mat-menu-item
                (click)="navigateNavbarLink(p)"
              >
                {{ p["titre_" + obs.langConfig.language] }}
              </button>
            </mat-menu>
          </li>
        </ul>
        <button
          class="btn btn-primary navbar__menu"
          (click)="sidebarVisible = !sidebarVisible"
        >
          <i class="fas fa-bars"></i>
        </button>
        <div class="navbar__search">
          <ng-container *ngIf="bms$ | async as bms">
            <button
              class="btn btn-sm btn-alert text-uppercase min-h-full"
              [class]="alertBtnClass"
              [ngClass]="{ 'btn-alert--pulse': bms.nb > 0 }"
              routerLink="/bms"
            >
              <span class="text-sm-3 alerteMeteo">{{
                "app.header.alerte_meteo" | translate
              }}</span>
            </button>
          </ng-container>

          <!--<div [style.direction]="obs.langConfig.direction">
            <fa-icon class="navbar__search__icon" [icon]="faSearch"></fa-icon>
            <input class="navbar__search__input" type="text" placeholder="{{'app.header.rechercher' |translate}}">
          </div>-->
        </div>
      </nav>

      <p-sidebar
        [(visible)]="sidebarVisible"
        [style]="{ padding: 0, width: '300px', overflow: 'auto' }"
        [position]="obs.langConfig.direction === 'ltr' ? 'left' : 'right'"
      >
        <div
          style="
            display: flex;
            margin-bottom: 1rem;
            padding: 1rem;
            box-shadow: 0 0px 6px rgba(0, 0, 0, 0.12),
              0 1px 2px rgba(0, 0, 0, 0.24);
          "
        >
          <img
            src="assets/img/logo-text.png"
            style="width: 100%"
            alt="Logo-onm"
          />
        </div>
        <mat-accordion>
          <div
            class="sidebar-link"
            routerLink="/home"
            routerLinkActive="sidebar-link-active"
            (click)="sidebarVisible = !sidebarVisible"
          >
            {{ "app.sidebar.home" | translate }}
          </div>
          <mat-expansion-panel
            *ngFor="let m of navbarMenus"
            class="mat-elevation-z0"
          >
            <mat-expansion-panel-header>
              <mat-panel-title>
                {{ m["titre_" + obs.langConfig.language] }}
              </mat-panel-title>
            </mat-expansion-panel-header>

            <ul style="list-style: none">
              <li
                class="sidebar-link"
                *ngFor="let p of m.pages"
                (click)="navigateNavbarLink(p, true)"
                routerLinkActive="sidebar-link-active"
              >
                {{ p["titre_" + obs.langConfig.language] }}
              </li>
            </ul>
          </mat-expansion-panel>

          <ul style="list-style: none">
            <li
              *ngFor="let l of topLinks"
              (click)="newTab(l.lien)"
              class="sidebar-link"
            >
              <i [class]="l.icon" class="mr-sm"></i>
              {{ l["nom_" + obs.langConfig.language] }}
            </li>
          </ul>

          <!--<mat-expansion-panel class="mat-elevation-z0">
            <mat-expansion-panel-header>
              <mat-panel-title>
                Autre liens
              </mat-panel-title>
            </mat-expansion-panel-header>

            

          </mat-expansion-panel>-->

          <ul
            class="sidebar-social social"
            [style.direction]="obs.langConfig.direction"
          >
            <li
              (click)="
                newTab('https://www.linkedin.com/company/meteoalgerieofficiel')
              "
              class="linkd"
            >
              <!-- <fa-icon
                [icon]="faLinkedinIn"
                class="sub-top-header__icon--right"
              ></fa-icon> -->
            </li>

            <li
              (click)="
                newTab('https://www.instagram.com/meteoalgerieofficiel/')
              "
              class="insta"
            >
              <!-- <fa-icon
                [icon]="faInstagram"
                class="sub-top-header__icon--right"
              ></fa-icon> -->
            </li>

            <li
              (click)="newTab('https://www.facebook.com/MeteoAlgerieOfficiel')"
              class="fcb"
            >
              <!-- <fa-icon
                [icon]="faFacebookF"
                class="sub-top-header__icon--right"
              ></fa-icon> -->
            </li>
            <li
              (click)="newTab('https://twitter.com/MeteoAlgerie_')"
              class="twt"
            >
              <!-- <fa-icon
                [icon]="faTwitter"
                class="sub-top-header__icon--right"
              ></fa-icon> -->
            </li>
            <li
              (click)="
                newTab(
                  'https://www.youtube.com/channel/UC-fiRD9_ncqzsSStt6S3_1w'
                )
              "
              class="ytb"
            >
              <!-- <fa-icon
                [icon]="faYoutube"
                class="sub-top-header__icon--right"
              ></fa-icon> -->
            </li>
            <li
              class="pls"
              (click)="
                newTab('https://play.google.com/store/apps/developer?id=ONM')
              "
            >
              <!-- <fa-icon
                [icon]="faGooglePlay"
                class="sub-top-header__icon--right"
              ></fa-icon> -->
            </li>
          </ul>

          <ul class="sidebar-langs mt-sm" *ngIf="langs$ | async as langs">
            <li
              class="lang-link"
              *ngFor="let l of langs"
              (click)="setLanguage(l.acr)"
              [ngClass]="{
                'top-link-active': obs.langConfig.language === l.acr
              }"
            >
              {{ l.acr | uppercase }}
            </li>
          </ul>
        </mat-accordion>
      </p-sidebar>

      <ng-container *ngIf="error$ | async as error">
        <div
          *ngIf="error.visible"
          class="error-banner text-white text-md text-bold-500 py-sm shadow-1"
        >
          <span><i class="fas fa-exclamation-triangle mr-sm"></i></span>
          {{ error.msg }}
        </div>
      </ng-container>

      <!-- APP CONTENT -->
      <div class="app-content">
        <!-- POPUP -->
        <ng-container *ngIf="popup$ | async as popup">
          <div *ngIf="popup.active" class="popup-event shadow-3">
            <div class="fill">
              <img
                class="popup-event__image"
                [src]="API_URL + popup.imgsrc"
                [alt]="popup.titre_alt"
              />
            </div>
            <div
              class="fas fa-times popup-event__close"
              (click)="popup.active = !popup.active"
            ></div>
          </div>
        </ng-container>

        <router-outlet></router-outlet>
      </div>

      <!-- FOOTER -->
      <footer class="footer-container">
        <div class="divider div-transparent"></div>

        <div class="footer-grid">
          <div class="footer-contact">
            <div class="footer-contact__address">
              <div>
                <img src="assets/img/logo-onm.png" alt="Logo" width="64" />
              </div>

              <div>
                <div class="footer-address-title">ONM Algérie</div>
                <div class="footer-address-content">
                  1, Avenue Mohamed Khemisti <br />
                  BP 153 Dar el Beida 16011 Alger
                </div>
              </div>
            </div>
            <div class="footer-contact__coordinates">
              <div class="footer-contact__coordinates__item">
                <fa-icon [icon]="faPhoneSquareAlt"></fa-icon> Tél: 023 67 39
                08/39 &nbsp; 023 67 61 09/10
              </div>
              <div class="footer-contact__coordinates__item">
                <fa-icon [icon]="faFax"></fa-icon> Fax: +213 (0) 23 67 39 99
              </div>
              <div class="footer-contact__coordinates__item">
                <fa-icon [icon]="faEnvelope"></fa-icon> Email:
                communication@meteo.dz
              </div>
            </div>

            <div class="py-2" *ngFor="let m of footerMenusLeft">
              <div
                class="text-sm-4 text-white text-bold-500"
                style="text-transform: uppercase"
              >
                {{ m["titre_" + obs.langConfig.language] }}
              </div>
              <ng-container *ngFor="let p of m.pages">
                <div>
                  <div
                    *ngIf="p.type_page != 'url'; else urlPage"
                    class="footer-link pt-xs"
                    (click)="onReadArticle(p['titre_fr'])"
                  >
                    {{ p["titre_" + obs.langConfig.language] }}
                  </div>

                  <ng-template #urlPage>
                    <div
                      class="footer-link pt-xs p-0"
                      (click)="navigateNavbarLink(p)"
                    >
                      {{ p["titre_" + obs.langConfig.language] }}
                    </div>
                  </ng-template>
                </div>
              </ng-container>
            </div>
          </div>

          <div class="footer-links">
            <div class="py-2" *ngFor="let m of footerMenusCenter">
              <div
                class="text-sm-2 text-white text-bold-500"
                style="text-transform: uppercase"
              >
                {{ m["titre_" + obs.langConfig.language] }}
              </div>
              <ng-container *ngFor="let p of m.pages">
                <div>
                  <div
                    *ngIf="p.type_page != 'url'; else urlPage"
                    class="footer-link"
                    (click)="onReadArticle(p['titre_fr'])"
                  >
                    {{ p["titre_" + obs.langConfig.language] }}
                  </div>
                </div>
                <ng-template #urlPage>
                  <div class="footer-link" (click)="navigateNavbarLink(p)">
                    {{ p["titre_" + obs.langConfig.language] }}
                  </div>
                </ng-template>
              </ng-container>
            </div>
          </div>

          <div class="footer-links">
            <div class="py-1" *ngFor="let m of footerMenusCenter1">
              <div
                class="text-sm-2 text-white text-bold-500"
                style="text-transform: uppercase"
              >
                {{ m["titre_" + obs.langConfig.language] }}
              </div>
              <ng-container *ngFor="let p of m.pages">
                <div>
                  <div
                    *ngIf="p.type_page != 'url'; else urlPage"
                    class="footer-link"
                    (click)="onReadArticle(p['titre_fr'])"
                  >
                    {{ p["titre_" + obs.langConfig.language] }}
                  </div>
                </div>
                <ng-template #urlPage>
                  <div class="footer-link" (click)="navigateNavbarLink(p)">
                    {{ p["titre_" + obs.langConfig.language] }}
                  </div>
                </ng-template>
              </ng-container>
            </div>
          </div>

          <div class="footer-products">
            <span class="text-md">Visiteurs: {{ visitors$ | async }}</span>
            <div
              class="rounded-sm mobile-ad"
              (click)="
                newTab('https://play.google.com/store/apps/developer?id=ONM')
              "
            >
              <img
                src="../assets/img/mobile-apps3.png"
                alt="Applications mobiles"
                style="
                  width: 80%;
                  height: 250px;
                  border-radius: 6px;
                  background-color: #fff;
                "
              />
            </div>
          </div>
        </div>
        <div class="footer-top-links">
          <ul
            class="sub-top-header__right social"
            [style.direction]="obs.langConfig.direction"
          >
            <span class="text-white"
              >{{ "app.header.suivez_nous" | translate }} :</span
            >

            <li
              (click)="
                newTab('https://www.linkedin.com/company/meteoalgerieofficiel')
              "
              class="linkd"
            >
              <!-- <fa-icon
                [icon]="faLinkedinIn"
                class="sub-top-header__icon--right"
              ></fa-icon> -->
            </li>
            <li
              (click)="
                newTab('https://www.instagram.com/meteoalgerieofficiel/')
              "
              class="insta"
            >
              <!-- <fa-icon
                [icon]="faInstagram"
                class="sub-top-header__icon--right"
              ></fa-icon> -->
            </li>

            <li
              (click)="newTab('https://www.facebook.com/MeteoAlgerieOfficiel')"
              class="fcb"
            >
              <!-- <fa-icon
                [icon]="faFacebookF"
                class="sub-top-header__icon--right"
              ></fa-icon> -->
            </li>
            <li
              (click)="newTab('https://twitter.com/MeteoAlgerie_')"
              class="twt"
            >
              <!-- <fa-icon
                [icon]="faTwitter"
                class="sub-top-header__icon--right"
              ></fa-icon> -->
            </li>
            <li
              (click)="
                newTab(
                  'https://www.youtube.com/channel/UC-fiRD9_ncqzsSStt6S3_1w'
                )
              "
              class="ytb"
            >
              <!-- <fa-icon
                [icon]="faYoutube"
                class="sub-top-header__icon--right"
              ></fa-icon> -->
            </li>
            <li
              class="pls"
              (click)="
                newTab('https://play.google.com/store/apps/developer?id=ONM')
              "
            >
              <!-- <fa-icon
                [icon]="faGooglePlay"
                class="sub-top-header__icon--right"
              ></fa-icon> -->
            </li>
          </ul>
        </div>
        <div class="flex-center text-white" style="background-color: #28367f">
          <!-- <img
            src="../assets/img/frise_degra_-01.png"
            width="40%"
            height="40px"
            alt=""
          /> -->
        </div>
        <div class="divider div-transparent div-dot"></div>
        <div class="flex-center text-white">
          COPYRIGHT © {{ todayDate | date : "YYYY" }} MeteoAlgerie - TOUS DROITS
          RÉSERVÉS
        </div>
      </footer>

      <div class="flash-info" [style.direction]="obs.langConfig.direction">
        <div>
          {{ "app.footer.flash_infos" | translate }} &nbsp; : &nbsp; &nbsp;
        </div>
        <ng-container *ngIf="VGFlashInfo">
          <div
            *ngIf="VGFlashInfo.length > 0; else noInfo"
            [class]="'text-' + displayedInfo.gravite | lowercase"
          >
            {{ displayedInfo.wilayas }}
          </div>
          <ng-template #noInfo>
            <div
              class="flash-info__content"
              style="color: var(--color-vigilance-vert)"
            >
              Aucune vigilance particulière
            </div>
          </ng-template>
        </ng-container>
      </div>
    </ng-container>
  </ng-container>

  <ng-template #sitewebMaintenanceTpl>
    <div style="height: 100vh">
      <div
        [innerHTML]="safeHTML(obs.maintenance.sitewebpage)"
        class="h-full grid place-items-center"
      ></div>
    </div>
  </ng-template>
</ng-container>

<ng-template #maintenanceViggen>
  <div class="text-sm-2 text-primary-dark text-bold-500">
    Carte de vigilance indisponible pour le moment
  </div>
</ng-template>
