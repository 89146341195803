import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { ReplaySubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class MaintenanceDataService {

  private _maintenanceSub = new ReplaySubject(1); // Replay without initial value
  maintenance$ = this._maintenanceSub.asObservable();

  constructor(private http: HttpClient) {
    this.findAll().subscribe(
      {
        next: (maintenance) => {
          this._maintenanceSub.next(maintenance)
        },
        error: (error) => {
        // TODO: Handle error
        }
      }
    )
  }

  findAll() {
    return this.http.get(`${environment.API_URL}/api/maintenance/`);
  }

}
