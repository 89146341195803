import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, ReplaySubject, Subject } from 'rxjs';
import { environment } from 'src/environments/environment';
import { MapHandlerService } from '../utility/map-handler.service';

const ICON_PATH = 'assets/icons/';

interface PhenomeneItem {
  name: string;
  icon: string;
  description: string;
  color?: string
}

const PREVISIONS_PHENOMENES: PhenomeneItem[] = [
  { name: 'Tous', icon: ICON_PATH + 'tous.png', description: 'Tous les phénomènes' },
  { name: 'Vent violent', icon: ICON_PATH + 'vent-violent.png', description: 'Vent violent' },
];

@Injectable({
  providedIn: 'root'
})
export class PrevisionDataService {

  private _prevMinimapSub = new Subject();
  prevMinimap$ = this._prevMinimapSub.asObservable();

  private _prevMapSub: Subject<any> = new ReplaySubject(1);
  prev$: Observable<any> = this._prevMapSub.asObservable();

  private _prevDaysSub: ReplaySubject<any> = new ReplaySubject(1);
  prevDays$: Observable<any> = this._prevDaysSub.asObservable();

  prevMapDataCache = {};
  prevDaysCache;

  private wilayasSub: BehaviorSubject<any> = new BehaviorSubject([]);
  wilayas$ = this.wilayasSub.asObservable();

  constructor(private http: HttpClient,
    private mapHandlerService: MapHandlerService,) {

  }

  showPrevisionsByDayTime(day: number = 0, begin: number = 6, end: number = 12) {
    this.mapHandlerService.initMapLoading();
    if (!this.prevMapDataCache[`${day}-${begin}-${end}`]) {
      this.findAllPrevisionsDayTime(day, begin, end).subscribe(
        (prevData) => {
          this.prevMapDataCache[`${day}-${begin}-${end}`] = prevData;
          //console.log("EMITTING PREV")
          this._prevMapSub.next({
            mapData: prevData,
            mapMode: 'previsions',
            phenomenes: []
          })
          this.mapHandlerService.stopMapLoading();
        },
        (error) => {
          // TODO: Handle error
        }
      )
    } else {
      //console.log("EMITTING PREV (CACHED)")
      this._prevMapSub.next({
        mapData: this.prevMapDataCache[`${day}-${begin}-${end}`],
        mapMode: 'previsions',
        phenomenes: []
      })
      this.mapHandlerService.stopMapLoading();
    }
  }

  showPrevisionMinimap(day: number = 0, begin: number = 6, end: number = 12) {
    this.findAllPrevisionsDayTime(day, begin, end).subscribe(
      (prevData) => {
        this.prevMapDataCache[`${day}-${begin}-${end}`] = prevData;
        this._prevMinimapSub.next({
          mapData: prevData,
          mapMode: 'previsions',
          phenomenes: []
        })
        this.mapHandlerService.stopMapLoading();
      },
      (error) => {
        // TODO: Handle error
      }
    )
  }

  showPrevDays() {
    if (!this.prevDaysCache) {
      this.find4daysPrev().subscribe(
        (data: any) => {
          data.days = data.days.map((day, i) => { // Mapping dates
            return { ...day, date: new Date().setDate(new Date().getDate() + i) }
          });
          this.prevDaysCache = data;
          this._prevDaysSub.next(data);
        },
        (error) => {
          // TODO: Handle error
        }
      );
    } else {
      this._prevDaysSub.next(this.prevDaysCache);
    }
  }

  findRssData() {
    return this.http.get(`${environment.API_URL}/api/rss/`);
  }

  emitWilayas(wilayas: any) {
    this.wilayasSub.next(wilayas);
  }

  findAllPrevisionsDayTime(day: number, time1: number, time2: number) {
    return this.http.get(`${environment.API_VIGILANCE}/api/prevs/${day}/${time1}-${time2}/`);
  }

  findByCodeWilaya(code: number) {
    return this.http.get(`${environment.API_VIGILANCE}/api/prevwilaya/${code}/`);
  }

  find4daysPrev() {
    return this.http.get(`${environment.API_VIGILANCE}/api/wilayajours/`);
  }

}
