import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ActualiteDataService {

  private _actuSub = new Subject();
  actu$: Observable<any> = this._actuSub.asObservable();

  private visitorsSub = new Subject();
  visitors$ = this.visitorsSub.asObservable();

  ONMYoutubeChannelId: string = 'UC-fiRD9_ncqzsSStt6S3_1w';
  ONMYoutubeAPIKey: string = 'AIzaSyBpaNZp62D2Vnwavtit27HS1rnhNQfLJ-s';

  constructor(private http: HttpClient) {
    //this.getByPageAndNumber(10, 1);
  }

  getByPageAndNumber(nb: number, page: number) {
    this.findByPageAndNumber(nb, page).subscribe(
      (data: any) => {
        this._actuSub.next({ data: data.elements, length: data.elements.length, total: data.nb_elements });
      },
      (error) => {
        // TODO: Handle error
      }
    );
  }

  getVisitors() {
    this.findNbVisitors().subscribe({
      next: (nb) => {
        this.visitorsSub.next(nb);
      },
    })
  }

  findBulletinMeteoURL() {
    return this.http.get(`${environment.API_URL}/api/lienyoutubes/`);
  }

  findAll() {
    return this.http.get(`${environment.API_URL}/api/actualites/`);
  }

  findByPageAndNumber(number: number, page: number) {
    return this.http.get(`${environment.API_URL}/api/actualites/${number}/${page}/`);
  }

  add(actu: any) {
    return this.http.post(`${environment.API_URL}/api/actualites/`, actu);
  }

  update(id: string, actu: FormData) {
    return this.http.put(`${environment.API_URL}/api/actualites/${id}/`, actu);
  }

  delete(id: string) {
    return this.http.delete(`${environment.API_URL}/api/actualites/${id}/`);
  }

  findByTitle(title: string) {
    return this.http.get(`${environment.API_URL}/api/actualitesurl/${title}/`);
  }

  findPopupEvent() {
    return this.http.get(`${environment.API_URL}/api/popup/`);
  }

  findNbVisitors() {
    return this.http.get(`${environment.API_URL}/api/counter/`);
  }

}
