import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ArticleItemComponent } from './pages/articles/article-item/article-item.component';


const routes: Routes = [
  { path: '', redirectTo: '/home', pathMatch: 'full' },
  { path: 'home', loadChildren: () => import('./pages/home/home.module').then(m => m.HomeModule) },
  { path: 'prevision', loadChildren: () => import('./pages/home/home.module').then(m => m.HomeModule) },
  { path: 'vigilance/:codeWilaya', loadChildren: () => import('./pages/home/home.module').then(m => m.HomeModule) },
  { path: 'articles/:pageTitle', loadChildren: () => import('./pages/articles/articles.module').then(m => m.ArticlesModule) },
  { path: 'article/:articleTitle', data: { type: 'article' }, component: ArticleItemComponent },
  { path: 'actualite/:articleTitle', data: { type: 'actualite' }, component: ArticleItemComponent },
  { path: 'bms', loadChildren: () => import('./pages/bms/bms.module').then(m => m.BmsModule) },
  { path: 'actualites', loadChildren: () => import('./pages/actualites/actualites.module').then(m => m.ActualitesModule) },
  { path: 'contact', loadChildren: () => import('./pages/contact/contact.module').then(m => m.ContactModule) },
  { path: 'rss', loadChildren: () => import('./pages/rss/rss.module').then(m => m.RssModule) },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy', scrollPositionRestoration: 'enabled' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
