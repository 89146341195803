<ng-container *ngIf="langConfig$ | async as langConfig">
  <ng-container *ngIf="article['titre_' + langConfig.language]; else noDataTpl">
    <div class="article-header" [style.direction]="langConfig.direction">
      <div class="article-info">
        <!-- <div class="article-title">
                    {{article["titre_"+langConfig.language]}}
                </div> -->

        <div
          class="article-title"
          [style.direction]="langConfig.direction"
          [innerHTML]="safeHTML(article['titre_' + langConfig.language])"
        ></div>

        <!-- <div class="article-description">
                    {{article["description_"+langConfig.language]}}
                </div> -->

        <div
          class="article-description"
          [style.direction]="langConfig.direction"
          [innerHTML]="safeHTML(article['description_' + langConfig.language])"
        ></div>

        <div class="article-date">
          {{ article.created_at | date : "dd/MM/YYYY" }}
        </div>
      </div>

      <div class="img-hover-zoom">
        <img
          [src]="API_URL + article.imgsrc"
          alt="Article image"
          class="article-image"
        />
      </div>
    </div>
    <div
      class="article-content cs-article-content-body"
      [style.direction]="langConfig.direction"
      [innerHTML]="safeHTML(article['contenu_' + langConfig.language])"
    ></div>

    <!--<pdf-viewer [external-link-target]="'blank'" [src]="'/assets/files/a_propos_onm.pdf'" [autoresize]="true"
            [original-size]='false' [render-text]="true" style="display: block;">
        </pdf-viewer>-->
  </ng-container>

  <ng-template #noDataTpl>
    <div class="grid place-items-center py-md">
      <div class="text-md text-white-3">
        {{ "article.non_disponible" | translate }}
      </div>
    </div>
  </ng-template>
</ng-container>
