import { Component, OnInit } from '@angular/core';
import {
  faNewspaper,
  faCamera,
  faBook,
  faChartBar,
  faArchive,
  faVideo,
  faShareAlt,
  faRss,
  faMap,
  faHome,
  faSearch,
  faPhoneSquareAlt,
  faFax,
  faEnvelope,
  faCloudSunRain,
  faCaretDown,
} from '@fortawesome/free-solid-svg-icons';
import {
  faInstagram,
  faFacebookF,
  faTwitter,
  faYoutube,
  faWikipediaW,
  faGoogle,
  faLinkedinIn,
  faGooglePlay,
  faApple,
} from '@fortawesome/free-brands-svg-icons';
import { NavbarDataService } from './core/services/backend/navbar-data.service';
import { SnackbarService } from './core/services/utility/snackbar.service';
import { Map, View } from 'ol';
import { Tile as TileLayer } from 'ol/layer';
import { MapHandlerService } from './core/services/utility/map-handler.service';
import { VigilanceDataService } from './core/services/backend/vigilance-data.service';
import { map, take } from 'rxjs/operators';
import VectorLayer from 'ol/layer/Vector';
import { BmsDataService } from './core/services/backend/bms-data.service';
import { Router } from '@angular/router';
import { TraductionService } from './core/services/utility/traduction.service';
import { MaintenanceDataService } from './core/services/backend/maintenance-data.service';
import { defaults as defaultMapInteractions } from 'ol/interaction';
import { defaults as DefaultControls } from 'ol/control';
import GeoJSON from 'ol/format/GeoJSON';
import { fromLonLat } from 'ol/proj';
import VectorSource from 'ol/source/Vector';
import { DomSanitizer } from '@angular/platform-browser';
import { PrevisionDataService } from './core/services/backend/prevision-data.service';
import { ActualiteDataService } from './core/services/backend/actualite-data.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  API_URL = environment.API_URL;

  title = 'meteo-website-frontend';
  todayDate = new Date();

  langs$ = this.traductionService.langs$;
  maintenance: any;
  maintenance$ = this.maintenanceDataService.maintenance$;
  visitors$ = this.actualityDataService.visitors$.pipe(
    map((data: any) => {
      return data.counter;
    })
  );

  navbarMenus = [];
  footerMenusLeft = [];
  footerMenusCenter = [];
  footerMenusCenter1 = [];
  topLinks = [];
  vigilance$ = this.vigilanceDataService.vigilanceGenerale$;
  previsionMiniMap$ = this.previsionDataService.prevMinimap$;
  popup$ = this.actualityDataService.findPopupEvent();
  mapData: any;

  VGFlashInfo = null;

  displayedInfo;

  // Sidebar
  sidebarVisible = false;

  faNewspaper = faNewspaper;
  faCamera = faCamera;
  faBook = faBook;
  faChartBar = faChartBar;
  faArchive = faArchive;
  faVideo = faVideo;
  faShareAlt = faShareAlt;
  faRss = faRss;
  faMap = faMap;
  faFacebookF = faFacebookF;
  faTwitter = faTwitter;
  faYoutube = faYoutube;
  faWikipediaW = faWikipediaW;
  faGoogle = faGoogle;
  faLinkedinIn = faLinkedinIn;
  faHome = faHome;
  faSearch = faSearch;
  faGooglePlay = faGooglePlay;
  faApple = faApple;
  faPhoneSquareAlt = faPhoneSquareAlt;
  faFax = faFax;
  faEnvelope = faEnvelope;
  faCaretDown = faCaretDown;
  faInstagram = faInstagram;

  // Weather Icons
  faCloudSunRain = faCloudSunRain;

  // MAP

  geoJsonLayer: VectorLayer<any>;

  map: Map;
  geoServerLayer: TileLayer<any>;
  bms$ = this.bmsDataService.bms$;
  langConfig$ = this.traductionService.langConfig$.pipe(
    map((lang) => {
      this.selectedLangConfig = lang;
      return lang;
    })
  );

  selectedLangConfig;
  error$ = this.snackbar.error$;

  cdCounter = 0;

  // Responsive
  alertBtnClass;

  constructor(
    private navbarDataService: NavbarDataService,
    private vigilanceDataService: VigilanceDataService,
    private previsionDataService: PrevisionDataService,
    private snackbar: SnackbarService,
    private mapHandlerService: MapHandlerService,
    private bmsDataService: BmsDataService,
    private router: Router,
    private traductionService: TraductionService,
    private maintenanceDataService: MaintenanceDataService,
    private sanitizer: DomSanitizer,
    private actualityDataService: ActualiteDataService
  ) {}

  ngOnInit() {
    this.maintenance$.pipe(take(1)).subscribe({
      next: (maintenance: any) => {
        this.maintenance = maintenance;
        if (maintenance.siteweb) {
          this.actualityDataService.getVisitors();

          this.initFlashInfos();

          this.previsionMiniMap$.pipe(take(1)).subscribe((data: any) => {
            if (data) {
              this.mapData = data.mapData;
              this._initMiniMap();
            }
          });

          this.vigilance$.pipe(take(1)).subscribe((data) => {
            const phenomenes = data.phenomenes.slice(1);
            if (phenomenes.some((d) => d.color === 'rouge'))
              this.alertBtnClass = 'btn-alert--rouge';
            else if (phenomenes.some((d) => d.color === 'orange'))
              this.alertBtnClass = 'btn-alert--orange';
            else if (phenomenes.some((d) => d.color === 'jaune'))
              this.alertBtnClass = 'btn-alert--jaune';
            else this.alertBtnClass = 'btn-alert--vert';
          });

          this.initLangs();
          this.initNavbarPages();
          this.initTopLinks();
          this.previsionDataService.showPrevisionMinimap(); // For minimap
          this.vigilanceDataService.emitVigilanceGenerale(); // intialiser VG
        }
      },
    });
  }

  navigateNavbarLink(page: any, fromSidebar = false) {
    if (fromSidebar) this.sidebarVisible = !this.sidebarVisible;

    if (page.type_page === 'url') {
      this.openLinkInPage(page.page_url);
    } else {
      this.router.navigate([
        'articles/' + page['titre_' + this.selectedLangConfig.language],
      ]);
    }
  }

  /*collapseMinimap() {
    this.map.getView().setZoom(2.5);
    const textFeature = this.geoJsonLayer.getSource().getFeatures().find(f => f.get('isMapText'))
    textFeature.setStyle(this.mapHandlerService.getLogoIcon(0.5))
  }*/

  get runChangeDetection() {
    return true;
  }

  setLanguage(lang: string) {
    this.traductionService.setLanguage(lang);
  }

  newTab(lien: string, local = false) {
    const url = lien.match(/^https?:/) ? lien : '//' + lien;
    local ? window.open(lien, '_blank') : window.open(url, '_blank');
  }

  openLinkInPage(lien: string) {
    const url = lien.match(/^https?:/) ? lien : '//' + lien;
    window.open(url, '_self');
    //local ? window.open(url, "_self") : window.open(url, "_blank");
  }

  initFlashInfos() {
    this.vigilanceDataService
      .findFlashInfo()
      .pipe(take(1))
      .subscribe(
        (data: any) => {
          this.VGFlashInfo = data
            .filter((d) => d.wilayas.length > 0)
            .map((d) => ({ ...d, wilayas: d.wilayas.join(', ') }));
          let i = 0;
          this.displayedInfo = this.VGFlashInfo[0];
          let l = this.VGFlashInfo.length;

          setInterval(() => {
            this.displayedInfo = this.VGFlashInfo[i++ % l];
          }, 3000);
        },
        (error) => {
          // TODO: Handle error
        }
      );
  }

  initLangs() {
    this.traductionService
      .findAllLanguages()
      .pipe(take(1))
      .subscribe(
        (langs) => {
          this.traductionService.emitActiveLanguages(langs);
        },
        (error) => {
          // TODO: Handle error
        }
      );
  }

  initNavbarPages() {
    this.navbarDataService
      .findAll()
      .pipe(take(1))
      .subscribe(
        (menus: any) => {
          this.navbarMenus = [...menus.filter((m) => m.pages.length > 0)];
          this.navbarMenus.forEach((m) => this._convertNavbarMenusTitles(m));
          this.footerMenusLeft = this.navbarMenus.slice(0, 1);
          this.footerMenusCenter = this.navbarMenus.slice(1, 4);
          this.footerMenusCenter1 = this.navbarMenus.slice(4, 5);
        },
        (error) => {
          // TODO: Handle error
          //this.snackbar.showError("Impossible de se connecter au serveur");
        }
      );
  }

  initTopLinks() {
    this.navbarDataService
      .findAllTopLinks()
      .pipe(take(1))
      .subscribe({
        next: (links: any) => {
          this.topLinks = links;
        },
        // TODO: Handle error
      });
  }

  private _convertNavbarMenusTitles(menu: any) {
    menu.pages = menu.pages.map((p) => ({
      ...p,
      titre: p.titre ? this._spaceToDash(p.titre) : '',
      displayedTitre: p['titre_' + this.selectedLangConfig],
    }));
  }

  private _spaceToDash(str: string) {
    return str.split(' ').join('-').trim();
  }

  private _initMiniMap() {
    this.mapHandlerService.readMiniMapFeatures().then((features) => {
      this.geoJsonLayer = new VectorLayer({
        source: new VectorSource({
          format: new GeoJSON(),
          features: features,
        }),
        declutter: true,
      });
      const layers = [this.geoJsonLayer];

      this.map = new Map({
        layers: layers,
        target: 'map2',
        view: new View({
          projection: 'EPSG:3857',
          center: fromLonLat([1.44197, 28.7525]),
          zoom: 3.2,
          minZoom: 0.5,
          maxZoom: 10,
        }),
        interactions: defaultMapInteractions({
          doubleClickZoom: false,
          dragPan: false,
          mouseWheelZoom: false,
        }),
        controls: DefaultControls({
          zoom: false,
        }),
      });

      this.mapHandlerService.setPrevStyle(
        this.geoJsonLayer.getSource(),
        this.mapData.features,
        false
      );
    });
  }

  navigateToVG() {
    if (this.router.url === '/home') {
      this.mapHandlerService.navigateMinimap('previsions');
    } else {
      this.router.navigate(['/home'], { state: { mapMode: 'previsions' } });
    }
  }

  onReadArticle(articleTitle: string) {
    const dashedTitle = this._spaceToDash(articleTitle);
    this.router.navigate(['articles/' + dashedTitle]);
  }

  safeHTML(unsafe: string) {
    return this.sanitizer.bypassSecurityTrustHtml(unsafe);
  }
}
