import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { SnackbarService } from './snackbar.service';

@Injectable({
  providedIn: 'root'
})
export class HttpInterceptorService implements HttpInterceptor {

  constructor(private snackbarService: SnackbarService) { }
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    return next.handle(req)

    /*.pipe(
      //retry(1),
      catchError((error: HttpErrorResponse) => {
        let errorMessage = '';
        if (error.error instanceof ErrorEvent) {
          // client-side error

          //errorMessage = `Error (Client): ${error.error.message}`;
          errorMessage = `Impossible de se connecter au serveur`;
        } else {
          // server-side error
          // errorMessage = `Error Code (Server): ${error.status}\nMessage: ${error.message}`;
          errorMessage = `Impossible de se connecter au serveur`;
        }
        //window.alert(errorMessage);
        this.snackbarService.emitError(errorMessage, true);
        return throwError(errorMessage);
      })
    )*/

  }
}
