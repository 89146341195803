import { Component, Input, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { TraductionService } from 'src/app/core/services/utility/traduction.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-article-view',
  templateUrl: './article-view.component.html',
  styleUrls: ['./article-view.component.scss']
})
export class ArticleViewComponent implements OnInit {

  @Input() article: any;

  langConfig$ = this.traductionService.langConfig$;

  API_URL = environment.API_URL;

  constructor(private traductionService: TraductionService,
    private sanitizer: DomSanitizer) { }

  ngOnInit(): void {
    
  }

  safeHTML(unsafe: string) {
    return this.sanitizer.bypassSecurityTrustHtml(unsafe);
  }

}
